import * as React from "react";
import styled from "styled-components";
// import { useEffect } from "react";


import QTilesSVG from "../images/svgs/qtiles.svg";
import QBathroomsSVG from "../images/svgs/qbathrooms.svg";
import QCabinetsSVG from "../images/svgs/qcabinets.svg";
import QKitchensSVG from "../images/svgs/qkitchens.svg";
import QBuiltSVG from "../images/svgs/qbuilt.svg";

import DesignStudioSVG from "../images/svgs/DesignStudio.svg";

// var qtiles_photo = "/images/qtiles.jpg";
// var qkitchens_photo = "/images/qkitchens.jpg";
// var qbathrooms_photo = "/images/qbathrooms.jpg";
// var qcabinets_photo = "/images/qcabinets.jpg";
// var qbuilt_photo = "/images/qbuilt.jpg";


const Button = styled.button`
	border:none;
`

const QButton = (props) => (
	<Button
		onClick={props.swapTo}
		id={props.name + "_button"}
		className={props.className}
	>
		<div>
			{props.svg}
			{/* <img alt={props.name} src={props.src} /> */}
		</div >
	</Button>
);

const StyledQButton = styled(QButton)`

	&:hover, &:focus {
		color: white !important;
		border-color: #888888 !important;
		background-color: rgba(255, 255, 255, 0.70) !important;
	}

	/* transform: translateY(10vh); */

	cursor: pointer;
	transition: all 0.3s ease;
  color: #24B3B0;
  background-color: rgba(255,255,255,0.35);
  min-width: 200px;
  margin: 5px 5px;
  text-align: center;
  display: inline-block;

  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 4px;

	div {
		pointer-events: none;
	}

  svg {
    height: 100px;
    vertical-align: middle;
    margin: 4px;
		pointer-events: none;

    @media(max-width: 419px) {
      height: 60px;
    }
  }

`;

const QButtons = (props) => {

	// useEffect(() => {
	// 	var pic1 = new Image();
	// 	var pic2 = new Image();
	// 	var pic3 = new Image();
	// 	var pic4 = new Image();
	// 	var pic5 = new Image();

	// 	pic1.src = qtiles_photo;
	// 	pic2.src = qkitchens_photo;
	// 	pic3.src = qbathrooms_photo;
	// 	pic4.src = qcabinets_photo;
	// 	pic5.src = qbuilt_photo;
	// }, [])

	const changeBg = props.changeBg;

	const swapTo = (props) => {
		const id = props.target.id.replace("_button", "");
		// console.log(props.target);
		// console.log(id);

		// if (typeof (item) == "object") {
		//   this.e = item.id();
		// } else {
		//   this.e = item.toLowerCase();
		// }

		// if(!qswap.active_section){
		//   var footers = window.jQuery("#colophon .widget");
		//   footers.show(100, function(){
		//     footers.addClass("show");
		//   });
		// }

		// if(qswap.active_section == this.e) return;
		//   qswap.active_section = this.e;

		//   if(!qswap.header_parallax_mirror){
		//     var mirrors = document.querySelectorAll(".parallax-mirror");
		//     qswap.header_parallax_mirror = mirrors[mirrors.length - 1];
		//   }

		// Photos defined in HTMl/JS Widget in main site.

		changeBg(id);
		// let new_img, new_y;
		// switch (id) {
		// 	case "qtiles":
		// 		new_img = qtiles_photo;
		// 		new_y = "60%";
		// 		break;
		// 	case "qkitchens":
		// 		new_img = qkitchens_photo;
		// 		new_y = "75%";
		// 		break;
		// 	case "qcabinets":
		// 		new_img = qcabinets_photo;
		// 		new_y = "60%";
		// 		break;
		// 	case "qbathrooms":
		// 		new_img = qbathrooms_photo;
		// 		new_y = "60%";
		// 		break;
		// 	case "qbuilt":
		// 		new_img = qbuilt_photo;
		// 		break;

		// 	default:
		// 		new_img = "/images/qc-design-studio.jpg";
		// 		break;
		// }

		// if(qswap.header_parallax_mirror){

		//   var imgClone = qswap.header_parallax_mirror.firstChild.cloneNode(true);
		//   qswap.header_parallax_mirror.appendChild(imgClone);
		//   window.jQuery(imgClone).fadeOut(600, function(){
		//     this.remove();
		//   });

		//   this.imgNode = qswap.header_parallax_mirror.firstChild;

		//   this.imgNode.setAttribute("src", new_img);

		// } else {
		//   // Device is mobile, or not loading parallax otherwise
		//   this.imgNode = window.jQuery(window.jQuery(".shapely_home_parallax > .cover > .parallax-window")[0]);
		//   this.imgNode.attr("style", "background-image: url('"+ new_img +"'); background-size: cover; background-position: center center;");
		// }

		let imgNode = document.querySelector(".qBody");
		let facadeNode = document.querySelector(".qBodyFacade");

		// Fade out old image
		facadeNode.style.backgroundImage = imgNode.style.backgroundImage;
		facadeNode.style.backgroundPositionY = imgNode.style.backgroundPositionY;
		facadeNode.classList.remove("fadeOut");

		// setTimeout(() => {
		// 	facadeNode.classList.add("fadeOut");

		// 	imgNode.style.backgroundImage = "url(" + new_img + ")";
		// 	imgNode.style.backgroundPositionY = new_y;
		// }, 20);

		document.querySelectorAll("main .info_section.show").forEach(function (e) {
			e.classList.remove("show");
		});

		document.querySelectorAll("." + id + "_segment").forEach(function (e) {
			e.classList.add("show");
		});
	};


	return (
		<div id="qbuttons" className={props.className}>
			<div>
				<h2>Click to learn more</h2>
				<StyledQButton swapTo={swapTo} changeBg={props.changeBg} name="qtiles" svg={<QTilesSVG />} />
				<StyledQButton swapTo={swapTo} changeBg={props.changeBg} name="qkitchens" svg={<QKitchensSVG />} />
				<StyledQButton swapTo={swapTo} changeBg={props.changeBg} name="qcabinets" svg={<QCabinetsSVG />} />
				<StyledQButton swapTo={swapTo} changeBg={props.changeBg} name="qbathrooms" svg={<QBathroomsSVG />} />
				<StyledQButton swapTo={swapTo} changeBg={props.changeBg} name="qbuilt" svg={<QBuiltSVG />} />
			</div>

			<div className="desStud">
				<DesignStudioSVG style={{ maxHeight: 30 }} />
			</div>
		</div>
	);
};

const StyledQButtons = styled(QButtons)`
  margin: auto;
  max-width: 500px;
  text-align:center;

	h2 {
		margin: 0 0 20px 0;
		padding-top: 20px;
		/* color: rgb(147, 151, 153); */
		/* text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; */
		
		
		color: #b6b6b6;
		font-size: 1.8rem;
		font-variant: small-caps;
		-webkit-text-stroke: 0.5px black;
	}

	/* margin-top: -80vh;

	@media(max-width: 434px) {
	margin-top: -90vh;
    } */

	.desStud {
		margin-top:20px;
		/* margin-top:15vh; */
    /* @media(min-width: 419px) {
			margin-top:15vh;
		} */
		
		/* position: absolute;
		left: 50%;
		bottom: 5vh;
		width: 100%; */
		
		svg {
			max-width: 85vw;
			/* left: -50%;
			position: relative; */
		}
	}
`

export default StyledQButtons