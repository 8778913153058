import * as React from "react";
import styled from "styled-components";
import QConceptsSVG from "../images/svgs/qconcepts-design-studio-nosize.svg";

const Header = (props) => (
  <nav className={props.className}>
    <a href="/">
      <QConceptsSVG />
    </a>
    <div>
      <ul>
        <li><span>Inspiration Addicts</span></li>
        <li><span>Tile Shop</span></li>
        <li><span>Sink / Tap / Basin Shop</span></li>
        <li><span>Kitchen Designers</span></li>
        <li><span>Cabinet Makers</span></li>
        <li><span>Colour / Style Consultants</span></li>
        <li><span>Project Managers</span></li>
        <li><span>Renovation Experts</span></li>
      </ul>
    </div>
  </nav>
)

const StyledHeader = styled(Header)`
  text-align: center;

	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  svg {
    height: 65px;
    vertical-align: middle;
		max-width: calc(100vw - 50px);
  }

  & > div {
    @media(max-width: 419px) {
      display: none;
    }
  }
  ul {

    pointer-events: none;
    max-width: 100vw;
    bottom: 37px;

    position: static;
    margin-left: -50vw;
    margin-right: -50vw;
    left: 50%;
    right: 50%;
    
    width: 97%;
    display: flex;
    margin: auto;
    overflow: hidden;
    align-items: center;
    
    padding: 0;
    list-style: none;

    li {
      flex: 1;
      color: #888;
      font-size: min(calc(0.45rem + 0.6vw),14px);

      
		/* color: #444; */
		font-variant: small-caps;
		/* -webkit-text-stroke: 0.5px black; */
    margin-top: 4px;
    }
  }
`

export default StyledHeader