import React, { useEffect, useState } from "react";
import "../styles/styles.css";
import styles from "../styles/style";
import styled, { keyframes } from "styled-components";
import { graphql } from "gatsby";

import {
  getImage,
  // GatsbyImage
} from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge';

import StyledHeader from "../components/Header";
import StyledQButtons from "../components/QButtons"
import StyledFooter from "../components/Footer";



const Section = (props) => {
  const classes = props.belongsTo
    .split(" ")
    .map((e) => e + "_segment")
    .join(" ");

  return (
    <div className={["info_section", classes, props.className].join(" ")}>
      <div>
        <div>
          <img src={props.photo} alt={props.name} />
        </div>
        <div>
          <h1>{props.name}</h1>
          <h2>{props.title}</h2>
          <h3>{props.subtitle}</h3>
          <h4>{props.exp}</h4>
          <p>{props.desc}</p>
        </div>
      </div>
    </div>
  );
};

const StyledSection = styled(Section)`

  padding: 24px;

  max-width: 800px;
  margin: auto;

  & > div {
    display: flex;
    flex-direction: column;

    & > div {
      margin: auto;
      padding: 16px;
    }
    
    @media(min-width: 768px) {
      flex-direction: row;
    }
  }

  img {
    height: auto;
    display: block;
    max-width: min(calc(100vw - 80px),300px);
    border: #24B3B0 1px solid;
    margin-bottom: 12px;
  }

  h1, h2, h3, h4, h5 {
    color: #444;
    font-weight: 500;
    margin: 4px 0;
  }
  /* h2, h3, h4 {
    margin-left: 12px;
  } */
  
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 12px;
  }
  h4 {
    margin-bottom: 18px;
  }
  p {
    font-size: .9rem;
    margin:0;
  }
`

const Sections = () => (
  <>
    <StyledSection
      photo="/images/photo_trish.jpg"
      name="Trisha Forbes"
      title="Dip Int Des [KBDi member]"
      subtitle="Interior Designer"
      exp="[Colour and Tile expert]"
      desc="Trisha has brought together her love of design, and expertise in hard finishes, to become one of the most sort after interior designers in Brisbane. She specialises in creating an overall ‘look’ in keeping with what is ‘on trend’, whilst helping clients select their tiles and stone, paint colours, cabinetry colours and finishes, for both commercial and residential applications. Trisha is also budget conscious and has been guiding builders, designers, architects and home renovators for over a decade."
      belongsTo="qtiles qbathrooms"
    />
    <StyledSection
      photo="/images/photo_karen.jpg"
      name="Karen Wilson"
      title="Dip Int Tech [KBDi member]"
      subtitle="Interior Designer"
      exp="[Kitchen and Joinery expert]"
      desc="Karen’s love for beautifully hand crafted joinery first started when she spent three years designing kitchens for a bespoke company in London. Since returning home to Brisbane ten years ago, she has enjoyed honing her skills on a wide variety of projects. Designing kitchens for Karen is not just a job, but a way of life. She has an excellent eye for detail and the ability to provide unique design solutions to meet anyone’s lifestyle, needs and budget."
      belongsTo="qkitchens qcabinets"
    />
    <StyledSection
      photo="/images/photo_michael.jpg"
      name="Michael Korsinczky"
      title="QBCC Builder Low-Rise / Builder Open"
      subtitle="Owner"
      exp="[Building and Project Management expert]"
      desc="Michael has had over 20 years building experience from renovating over 15 of his own houses to starting his building company back in 2007. Qconcepts was created by Michael to fill a niche and give clients a one-stop-shop service experience from design to specification / supply to installation if required."
      belongsTo="qcabinets qbuilt"
    />
  </>
)


const fadeOut = keyframes`
  from {
    /* filter: opacity(0); */
    opacity: 1;
  }
  to {
    /* filter: opacity(1); */
    opacity: 0;
  }
`

const MainBody = (props) => {

  var pluginImage = (props.bgImage);

  // console.log(props.bgImage);

  return (
    <BgImage image={pluginImage} style={{ minWidth: 200, minHeight: 200 }} className={props.className}>
      {props.children}
    </BgImage>
  )
}

const StyledMainBody = styled(MainBody)`
  height: 85vh;
  // transform: translate3d(0,20%,0);

  /* background-image: url("/images/qc-design-studio.jpg");
  background-size: cover;
  background-position-x: center; */

  .qBodyFacade {
    height: 100%;
    /* position: sticky; */
    position: absolute;
    transition: opacity;
    opacity: 0;
    background-size: cover;
    background-position-x: center;
    
    &.fadeOut{
      animation: ${fadeOut} .8s ease;
    }
  }
`


// const InstaSection = (props) => {

//   // console.log(props);
//   return (
//     <div className={props.className}>
//       <h2>Our Work</h2>
//       <div>
//         {props.images.map(image => {
//           // console.log(image);

//           return (
//             <span key={image.id} >
//               {/* <Image
//               {image.preview}
//               {image.caption}
//               {image.dimensions.height} x
//               {image.dimensions.width}
//             /> */}
//               <GatsbyImage
//                 alt="Hi"
//                 image={image.localFile.childImageSharp.gatsbyImageData}
//               />
//             </span>
//           )
//         })}
//       </div >
//     </div >
//   )
// }

// const StyledInstaSection = styled(InstaSection)`
//   max-width:800px;
//   margin: auto;
//   text-align: center;

//   div {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-around;
//   }

//   span {
//     margin: 4px;
//     padding: 4px;


//   }

//   img {
//     border: #24B3B0 1px solid;
//     border-radius: 12px;
//   }
// `

const CuratorSection = (props) => {

  useEffect(() => {
    var i, e, d = document, s = "script"
    i = d.createElement("script")
    i.async = 1;
    i.src = "https://cdn.curator.io/published/711bd53a-8cc3-4667-aa9e-a1b88e0aae51.js";
    e = d.getElementsByTagName(s)[0]
    e.parentNode.insertBefore(i, e);
  }, [])

  if (typeof window == 'undefined') {
    return (<div className={props.className}>Loading...</div>)
  }

  // (function(){
  //   var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
  //   i.src="https://cdn.curator.io/published/711bd53a-8cc3-4667-aa9e-a1b88e0aae51.js";
  //   e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
  //   })();

  return (
    <div className={props.className}>
      <h2>Our Work</h2>
      <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
    </div>
  )
}

const StyledCuratorSection = styled(CuratorSection)`
  max-width:800px;
  margin: auto;
  text-align: center;
  
  &::before{
    /* border-top: 1px solid rgba(0, 0, 0, .3); */
    content: "";
    position: absolute;
    display: block;
    
    width:100%;
    height: 1px;
    left: 0;
    
    background-color: #AAA;
    /* background-image: linear-gradient(120deg,#eaee44,#33d0ff); */
  }

  h2 {
		color: #555;
		font-size: 1.3rem;
    letter-spacing: .1rem;
    font-weight: 400;
		font-variant: small-caps;
		/* -webkit-text-stroke: 0.5px black; */
    padding-top: 12px;
    margin-top: 0;
    margin-bottom: 12px;
    text-decoration: underline;
  }

  /* & > div#curator-feed-default-feed-layout {
    padding-bottom: 0;
  } */
`

// markup
const IndexPage = (props) => {

  const [bgImage, setBgImage] = useState(getImage(props.data.placeHolderImage))

  const bgImages = {
    default: props.data.placeHolderImage,
    qtiles: props.data.qtilesPlaceHolderImage,
    qkitchens: props.data.qkitchensPlaceHolderImage,
    qcabinets: props.data.qcabinetsPlaceHolderImage,
    qbathrooms: props.data.qbathroomsPlaceHolderImage,
    qbuilt: props.data.qbuiltPlaceHolderImage
  }

  const changeBg = (name) => {
    // console.log(name)

    let image;

    switch (name) {
      case "qtiles":
        image = bgImages.qtiles;
        // new_y = "60%";
        changeBg("qbuilt");
        break;
      case "qkitchens":
        image = bgImages.qkitchens;
        // new_y = "75%";
        break;
      case "qcabinets":
        image = bgImages.qcabinets;
        // new_y = "60%";
        break;
      case "qbathrooms":
        image = bgImages.qbathrooms;
        // new_y = "60%";
        break;
      case "qbuilt":
        image = bgImages.qbuilt;
        break;

      default:
        image = bgImages.default;
        break;
    }

    // console.log(image)

    setBgImage(getImage(image))
  }

  // console.log(props);
  // const images = props.data.instaImages.nodes;

  return (
    <main style={styles.pageStyles}>
      <title>QConcepts - Tiles and Renovations</title>

      <StyledHeader />

      <StyledMainBody bgImage={bgImage} className="qBody">
        <div className="qBodyFacade" />
        <StyledQButtons changeBg={changeBg} />
      </StyledMainBody>
      <Sections />
      {/* <StyledInstaSection images={images} /> */}

      <StyledCuratorSection />

      <StyledFooter />

    </main>
  );
};

export const query = graphql`
  query {
  placeHolderImage: file(relativePath: {eq: "qc-design-studio.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, blurredOptions: {width: 20}, formats: [AUTO, WEBP, AVIF])
    }
  }
  qtilesPlaceHolderImage: file(relativePath: {eq: "qtiles.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
    }
  }
  qkitchensPlaceHolderImage: file(relativePath: {eq: "qkitchens.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
    }
  }
  qcabinetsPlaceHolderImage: file(relativePath: {eq: "qcabinets.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
    }
  }
  qbathroomsPlaceHolderImage: file(relativePath: {eq: "qbathrooms.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
    }
  }
  qbuiltPlaceHolderImage: file(relativePath: {eq: "qbuilt.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
    }
  }

  
  # instaImages: allInstaNode(filter: {caption: {glob: "*qkitchens*"}}) {
#   instaImages: allInstaNode {
#     nodes {
#       id
#       caption
#       original
#       localFile {
#         childImageSharp {
#           id
#           gatsbyImageData(layout: FIXED, height: 150, width: 150)
#           original {
#             height
#             width
#           }
#         }
#       }
#     }
#     totalCount
#   }
}


`

export default IndexPage;
