import * as React from "react";
import styled from "styled-components";

const Contact = (props) => (
	<>
		<div className="contact-phone">
			<span>{props.for} [{props.name}]: </span>
			<span itemProp="telephone">{props.phone}</span>
		</div>
		<div className="contact-email">
			<a rel="noreferrer" href={"mailto:" + props.email} target="_blank">
				{props.email}
			</a>
		</div >
	</>
)

const Footer = (props) => (
	<footer className={props.className} role="contentinfo">
		<div className="footer-widget-area">
			<div
				role="complementary"
			>
				<div
					className="show"
				>
					<h2>Location</h2>
					<div className="contact-location" itemScope="" itemType="http://schema.org/LocalBusiness">
						<iframe
							title="QConcepts Location"
							width="600"
							height="216"
							loading="lazy"
							frameBorder="0"
							src="https://www.google.com/maps/embed/v1/place?q=Qconcepts+-+34+Nile+Street+Woolloongabba+4102&amp;key=AIzaSyDSZqoERTUu58ZenntfZtWHfEMStIOBhXs&amp;zoom=13"
							rel="noreferrer"
							className="contact-map"
						></iframe>
						<div
							className="contact-address"
							itemScope=""
							itemType="http://schema.org/PostalAddress"
							itemProp="address"
						>
							<a
								href="https://maps.google.com/maps?z=16&amp;q=qconcepts+-+34+nile+street+woolloongabba+4102"
								target="_blank"
								rel="noreferrer"
							>
								QConcepts - 34 Nile Street Woolloongabba 4102
							</a>
						</div>
					</div>
					<div className="contact-info-text">
						<div>
							<h2>Contact Info & Hours</h2>
							<Contact
								for="Tiles / Bathware"
								name="Trish"
								phone="0417 639 438"
								email="trish@qtiles.com.au"
							/>
							<Contact
								for="Kitchens / Cabinets"
								name="Karen"
								phone="0431 398 856"
								email="karen@qkitchens.com.au"
							/>
							<Contact
								for="Bathrooms / Renovations"
								name="Michael"
								phone="0414 561 121"
								email="michael@qbathrooms.com"
							/>

							<div className="contact-hours" itemProp="openingHours">
								Monday-Friday: 9am - 5pm
								<br />
								Saturday: By Appointment
								<br />
								Sunday: Closed
							</div>
						</div>
						<div>
							<h2>Other</h2>
							<a href="/QconceptsTC.pdf">Terms and Conditions</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer >
);

const StyledFooter = styled(Footer)`

	border-top: 1px solid rgba(0, 0, 0, 0.1);

	& > div {
		max-width: 800px;
		margin: auto;
	}

	color: #333;
	font-size: 14px;
	padding: 24px;

	a {
		color: #1F9796;
		// text-decoration: none;
		font-weight: 600;
	}
	
	.contact-location {
		text-align: center;
		/* padding: 0 1rem; */
	}

	.contact-address {
		padding-top:8px;
		padding-bottom:16px;
	}
	
	.contact-map {
		border: 1px solid #2B7A78;
		width:100%;
		max-width: 800px;
	}

	.contact-email {
		padding-bottom: 20px;
	}

	.contact-info-text {
		display: flex;

		// & > div:first-child() {
		// 	flex: 1 2;
		// }

		& > div:first-child {
			flex-grow: 1;
		}

		@media (min-width:601px) {
			& > div:nth-child(1) h2, & > div:nth-child(1) {
				text-align: left !important;
			}
			& > div:nth-child(2) h2, & > div:nth-child(2) {
				text-align: right !important;
			}
		}
		
		@media (max-width:600px) {
			flex-direction: column;

			& > div:nth-child(2){
				margin-top: 1rem;
			}
		}
	}

	h2 {
		text-align: center;
		color: #444444;
		font-variant: small-caps;
		/* text-transform: capitalize; */
		font-weight: 500;

		font-size: 22px;
    line-height: 24px;
		margin-top: 0;
		margin-bottom: 25px;
	}
`

export default StyledFooter