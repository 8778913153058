var styles = {

	htmlStyles: {
		backgroundColor: "#EAEAEA",
	},

	pageStyles: {
		color: "#232129",
		padding: 0,
		margin: "none",
		fontFamily: "-apple-system, Roboto, sans-serif, serif",
	},
	headingStyles: {
		marginTop: 0,
		marginBottom: 64,
		maxWidth: 320,
	},
	headingAccentStyles: {
		color: "#663399",
	},
	paragraphStyles: {
		marginBottom: 48,
	},
	codeStyles: {
		color: "#8A6534",
		padding: 4,
		backgroundColor: "#FFF4DB",
		fontSize: "1.25rem",
		borderRadius: 4,
	},
	listStyles: {
		marginBottom: 96,
		paddingLeft: 0,
	},
	listItemStyles: {
		fontWeight: 300,
		fontSize: 24,
		maxWidth: 560,
		marginBottom: 30,
	},

	linkStyle: {
		color: "#8954A8",
		fontWeight: "bold",
		fontSize: 16,
		verticalAlign: "5%",
	},

	descriptionStyle: {
		color: "#232129",
		fontSize: 14,
		marginTop: 10,
		marginBottom: 0,
		lineHeight: 1.25,
	},

	docLink: {
		text: "Documentation",
		url: "https://www.gatsbyjs.com/docs/",
		color: "#8954A8",
	},

	badgeStyle: {
		color: "#fff",
		backgroundColor: "#088413",
		border: "1px solid #088413",
		fontSize: 11,
		fontWeight: "bold",
		letterSpacing: 1,
		borderRadius: 4,
		padding: "4px 6px",
		display: "inline-block",
		position: "relative",
		top: -2,
		marginLeft: 10,
		lineHeight: 1,
	},

	sectionStyles: {
		display: "none",
		backgroundColor: "red",
		marginLeft: 100,
	}
}

styles.docLinkStyle = {
	...styles.linkStyle,
	listStyleType: "none",
	marginBottom: 24,
}

export default styles